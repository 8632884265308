jQuery(document).ready(function(){
  // Banner background image
  jQuery('.single-grid').each(function(){
    var bg_img = jQuery(this).find('.img-wrap img').attr('src');
    jQuery(this).css('background-image', 'url(' + bg_img + ')');
  });

  jQuery('body').click(function(){
    jQuery('.main-menu').slideUp();
  });
  jQuery('.toggle-btn').click(function(event){
    event.stopPropagation();
    jQuery('.main-menu').slideToggle();
  });

  // Menu target scroll js
  jQuery('.main-menu li, .banner-wrap .text-content ').on('click','a', function(event) {
      event.preventDefault();
      var target = "#" + this.getAttribute('data-target');

      jQuery('.main-menu li a').removeClass('active');
      jQuery(this).addClass('active');

      jQuery('html, body').animate({
          scrollTop: jQuery(target).offset().top - 140
      }, 1000);
  });

  // Custom checkbox js
  jQuery('input[type="checkbox"], input[type="radio"]').wrap('<div class="input-rc"></div>');
  jQuery('.input-rc').append('<span class="input-rc-span"></span>');

});

  